<template>
  <v-container pt-2 pb-1>
    <page-title :title="pageTitle" />
    <eagle-form :form-key="formKey">
      <template v-slot:footer>
        <v-alert v-show="errorMessage" dense outlined type="error">{{
          errorMessage
        }}</v-alert>
        <!-- LinePay -->
        <v-card v-show="hasLinePay" class="my-3">
          <v-card-title>{{ $t(`data.payment_type.linepay`) }}</v-card-title>
          <v-card-text>
            <v-text-field
              label="Channel ID"
              outlined
              v-model="linePaySetting.ChannelId"
            ></v-text-field>
            <v-text-field
              label="Channel Secret Key"
              outlined
              v-model="linePaySetting.ChannelSecret"
            ></v-text-field>
          </v-card-text>
        </v-card>
        <!-- ECPay -->
        <v-card v-show="hasECPay" class="my-3">
          <v-card-title
            >{{ $t(`data.payment_type.ecpay`) }} - 綠界</v-card-title
          >
          <v-card-text>
            <v-text-field
              label="特店編號(MerchantID)"
              outlined
              v-model="ECPaySetting.MerchantID"
            ></v-text-field>
            <v-text-field
              label="串接金鑰HashKey"
              outlined
              v-model="ECPaySetting.HashKey"
            ></v-text-field>
            <v-text-field
              label="串接金鑰HashIV"
              outlined
              v-model="ECPaySetting.HashIV"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </template>
    </eagle-form>
  </v-container>
</template>
<script>
import formMixin from "@/components/form/mixins/formMixin";
import formConfig from "./providerPaymentSetting";
import { paymentTypes } from "modules/base/config/paymentConstantsForB2C";

export default {
  mixins: [formMixin],
  data: () => ({
    formKey: "provider-payment-setting-form",
    errorMessage: "",
    linePaySetting: {
      ChannelId: "",
      ChannelSecret: "",
    },
    ECPaySetting: {
      MerchantID: "",
      HashKey: "",
      HashIV: "",
    },
    meta: {},
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`];
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`];
    },
    paymentType() {
      if (!this.formData) return null;
      return this.formData.payment_type;
    },
    hasLinePay() {
      if (!this.formData) return false;
      if (!this.formData.payment_type) return false;
      return !!this.formData.payment_type.includes("linepay");
    },
    hasECPay() {
      if (!this.formData) return false;
      if (!this.formData.payment_type) return false;
      return !!this.formData.payment_type.includes("ecpay");
    },
  },
  watch: {},
  mounted() {
    this.getPaymentSetting();
  },
  methods: {
    providerPaymentTypesIsEmpty(providerPaymentTypes) {
      let isEmpty = true;
      for (let i in providerPaymentTypes) {
        const curr = providerPaymentTypes[i];
        if (paymentTypes.includes(curr)) {
          isEmpty = false;
        }
      }
      return isEmpty;
    },
    async beforeFormInit() {
      await Promise.all([]);
    },
    validateBeforeSave() {
      if (this.providerPaymentTypesIsEmpty(this.paymentType)) {
        this.errorMessage = this.$t("payment_type_is_empty");
        return true;
      }
      return false;
    },
    async getPaymentSetting() {
      // if (this.hasLinePay)
      this.linePaySetting = await this.$api.collection.providerApi.getPaymentSettingForLinePay(
        this.providerId
      );
      // if (this.hasECPay)
      this.ECPaySetting = await this.$api.collection.providerApi.getPaymentSettingForECPay(
        this.providerId
      );
    },
    async readApi(target) {
      const res = await this.$api.collection.providerApi.read(this.providerId);
      this.$store.dispatch("base/setLayoutProvider", res);
      return res;
    },
    async updateApi(target, formData) {
      try {
        const res = await this.$api.collection.providerApi.update(
          this.providerId,
          formData
        );
        // this.$store.dispatch('base/setLayoutProvider', res)
      } catch (error) {
        console.warn(error);
      } finally {
        if (this.hasLinePay)
          this.$api.collection.providerApi.setPaymentSettingForLinePay(
            this.providerId,
            this.linePaySetting
          );
        if (this.hasECPay)
          this.$api.collection.providerApi.setPaymentSettingForECPay(
            this.providerId,
            this.ECPaySetting
          );
      }
    },
    getFormConfig() {
      return formConfig;
    },
  },
};
</script>
<style lang=""></style>
